<template>
<div class="inner">
    <div class="page_title">
        <div class="container">Solutions</div>
    </div>
    <div class="container">
        <a-row :gutter="10">
            <a-col :span="24" v-for="(solution, idx) in solutions" :key="idx"> 
                <div class="box flex"> 
                    <img :src="solution.icon_url" class="icon">
                    <div class="flex_column">
                        <span class="heading">{{solution.heading}}</span>
                        <span class="sub_heading">{{solution.sub_heading}}</span>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</div>
</template>
<script>
import { getSolutions  } from '@/apis/home/solutions'
export default {
    data(){
        return {
            solutions: []
        }
    },
    methods: {
        async getSolutionsHandler(){
            const res = await getSolutions()
            this.solutions = res.list
        }
    },
    created(){
        this.getSolutionsHandler()
    }
}
</script>
<style lang="stylus" scoped>
.box
    margin-bottom 20px
.icon
    width 120px
    display block
    margin 10px auto
.heading
    font-size 18px
    margin-bottom 10px
.sub_heading
    font-size 14px
    color #abb1bf
.flex_column
    justify-content center
</style>